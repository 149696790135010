<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2025                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <blueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
</template>

<script>
import { Role } from "@/router/role.js";
import BlueHeader from '../../../../components/BlueHeader.vue';

export default {
  components: { BlueHeader },
  name: 'Maestro',
  data: () => ({
    itemsHeader: [
      {
        name: 'Homologación',
        link: { name: 'modules.settings.maestro.homologacion' },
        rol: Role.Configuraciones_Maestro_Homologacion,
      },

    ]
  }),
}
</script>

<style scoped>
.subHeader-content {
  width: 100%;
}
</style>